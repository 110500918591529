import { api } from "../../../api/axios-base";

export const fetchRemoveSubject = async (subjectId) => {
  try {
    const { data } = await api.delete(`/app-personas/materia/${subjectId}`);
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchReactivateSubject = async (subjectId) => {
  try {
    const { data } = await api.post(`/app-personas/helpers/reactivar-materia`,{
      id_materia: subjectId
    });
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fetchGetSubject = async (subjectId) => {
  try {
    const { data } = await api.get(`/app-personas/materia/${subjectId}`);
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fecthPutSubject = async (subjectId, subject) => {
  try {
    const { data } = await api.put(`/app-personas/materia/${subjectId}`,{
        ...subject,
        institucion_educativa: subject.institucion_educativa.id,
        titular_materia: subject.titular_materia,
        campos_formativos: subject.campos_formativos,
        plan_estudios: subject.plan_estudios
      }
    );
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};

export const fecthPutSubjectTeacher = async (subjectId, subject) => {
  try {
    const { data } = await api.put(`/app-personas/materia-profesor/${subjectId}`,{
        ...subject,
      }
    );
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fecthPostSubject = async (subject) => {
  try {
    const { data } = await api.post(`/app-personas/materia`,{
        ...subject,
      }
    );
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};