<template>
  <div>
    <v-row justify="space-between">
      <v-col v-for="item in items" :key="`tab-${item.name}`">
        <v-btn
          v-if="!tos"
          outlined
          tile
          block
          :disabled="item.disabled"
          :to="tos ? { name: item.name } : null"
          :color="active(item.value)"
          @click="emitValue(item.value)"
        >
          {{ item.name }}
        </v-btn>
        <router-link
          v-if="tos"
          :to="{ name: item.name }"
          custom
          v-slot="{ isActive, route }"
        >
          <v-btn
            outlined
            tile
            block
            :disabled="item.disabled"
            :to="{ name: route.name }"
            :color="isActive ? 'primary' : 'grayoscuro'"
            @click="emitValue(item.value)"
          >
            {{ item.title ? item.title : route.name }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-progress-linear
          v-model="valueDeterminate"
          color="primary"
          rounded
          height="8"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    tos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueDeterminate: 0,
    };
  },
  created() {
    // this.items.length
    //   ? (this.valueDeterminate = this.items[0].value)
    //   : (this.valueDeterminate = 0);
    this.setFirstActive();
  },
  methods: {
    active(val) {
      if (this.valueDeterminate === val) {
        return 'primary';
      }
      return 'grayoscuro';
    },
    emitValue(val) {
      this.valueDeterminate = val;
      this.$emit('tabValue', this.valueDeterminate);
    },
    setFirstActive() {
      if (this.items.length) {
        const index = this.items.findIndex((item) => !item.disabled);
        if (index !== -1) {
          this.valueDeterminate = this.items[index].value;
        } else {
          this.valueDeterminate = this.items[0].value;
        }
      } else {
        this.valueDeterminate = 0;
      }
      this.$emit('tabValue', this.valueDeterminate);
    },
  },
};
</script>

<style></style>
