import { api } from "../../../api/axios-base";

export const fetchDeleteCriterion = async (criterionId) => {
  try {
    const { data } = await api.delete(`/app-personas/criterio-evaluacion/${criterionId}`);
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchPostCriterion = async (criterion) => {
  try {
    const { data } = await api.post(`/app-personas/criterio-evaluacion`,
      criterion
    );
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchPutCriterion = async (criterionId, criterion) => {
  try {
    const { data } = await api.put(`/app-personas/criterio-evaluacion/${criterionId}`,
      criterion
    );
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};
export const fetchGetCriterion = async (criterionId) => {
  try {
    const { data } = await api.get(`/app-personas/criterio-evaluacion/${criterionId}`);
    if (data.e)
      return { ok: false };
    return { data, ok: true };
  } catch (error) {
    return { ok: false, message: error?.toString() };
  }
};